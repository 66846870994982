import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser,CallMapMethod_CBack,
  ajaxindicatorstart,ajaxindicatorstop} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import Comp_Narmadeayurvedam_Course from '../sample_comp_v2/comp_narmadeayurvedam_course'
/*Image */
import loginPng from '../assets/images/icons/login.png';

import './login_new.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let logInObj="";
class Tmp_Courses extends React.Component{
  resendOtp(){
    let mapOtp=[];
    mapOtp.push({name:"curl_type",value:"resendOTP"});
    mapOtp.push({name:"res_func",value:"callBackSt"});
    mapOtp.push({name:"ref_id",value:$("#refotp_id").val()});
    mapOtp["res_func"]="callBackSt";
    mapOtp["curl_type"]="resendOTP";
    ajaxindicatorstart("sending request ,please wait..");
    CallMapMethod_CBack(clsObject,mapOtp,function(data,msg){
      ajaxindicatorstop();
      if(data!=="0"){
        alert("OTP resend successfully");
      }else{
        alert("Unable to send OTP , kindly check or contact us");
      }
    })
    
  }  
  setClickFor(idName){
    if(idName=="nav_contactotp"){
      //document.getElementById("nav_login").classList.remove("active");
      //document.getElementById("nav_regsi").classList.remove("active");

      document.getElementById("lg1").classList.remove("active");
      //document.getElementById("lg2").classList.remove("active");
      document.getElementById("lg3").classList.remove("active");
      document.getElementById("lg5").classList.remove("active");
      
      document.getElementById("lg4").classList.add("active");
    }else if(idName==="nav_otp"){
      //alert("i m in nav_otp");
      document.getElementById("nav_login").classList.remove("active");
      //document.getElementById("nav_regsi").classList.add("active");

      //document.getElementById("lg2").classList.remove("active");
      document.getElementById("lg1").classList.remove("active");
      document.getElementById("lg4").classList.remove("active");
      document.getElementById("lg5").classList.remove("active");

      document.getElementById("lg3").classList.add("active");
    }else if(idName==="nav_regsi"){
        document.getElementById("nav_login").classList.remove("active");
        //document.getElementById("nav_regsi").classList.add("active");
        //document.getElementById("lg2").classList.add("active");
        document.getElementById("lg1").classList.remove("active");
        document.getElementById("lg3").classList.remove("active");
        document.getElementById("lg4").classList.remove("active");
        document.getElementById("lg5").classList.remove("active");
        }else if(idName==="nav_setpassword"){
        document.getElementById("lg5").classList.add("active");
        //document.getElementById("nav_regsi").classList.remove("active");
        //document.getElementById("lg2").classList.remove("active");
        document.getElementById("lg1").classList.remove("active");
        document.getElementById("lg3").classList.remove("active");
        document.getElementById("lg4").classList.remove("active");
        document.getElementById("nav_login").classList.remove("active");
        }
        else{
          //alert("i am in back to login");
        document.getElementById("nav_login").classList.add("active");
        //document.getElementById("nav_regsi").classList.remove("active");
        //document.getElementById("lg2").classList.remove("active");
        document.getElementById("lg1").classList.add("active");
        document.getElementById("lg3").classList.remove("active");
        document.getElementById("lg4").classList.remove("active");
        document.getElementById("lg5").classList.remove("active");
      }
    
    //$("#nav_regsi").click();
  }
    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userDS")!=null){
          logInObj=JSON.parse(localStorage.getItem("userDS"));
        }

        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
        clsObject=this;
      var pgMap=[];
      pgMap.push({name:"curl_type",value:"getPgData"});
      pgMap.push({name:"res_func",value:"callBackpgData"});
      pgMap.push({name:"loc",value:loc});
     // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
      //mapCat.push({name:"emp_id",value:userObject[0].id});
      pgMap["res_func"]="callBackpgData";
      pgMap["curl_type"]="getPgData";
      CallCMSMapMethod(clsObject,pgMap);
      AjaxCallMethod(this,"okSuccCNOT");
    }
    okSuccLogn(data,datares){
        if(data==="not_validated"){
          $("#getRegLogn").html(datares);
        }else{
//  alert(data);
//  return;
 if(data!=="0" && data!=="" && data!=="not_validated"){
  $("#getRegLogn").html("Login Successfully..");
 localStorage.setItem('userDS',data);
 window.location.href="/home";
}else{
  $("#getRegLogn").html("Invalid UserName or Password !");
}
    }
  }
  homeRedirect(){
    window.location.href="/home";
  }

  okSuccForgetPass(data,datares){
    //alert(data+datares);
    if(data==="001"){
      $("#getRegPasswordMsg").html(datares);
      setTimeout(function() {
        window.location.href="../home";
      }, 1000);

    }else {
      $("#getRegPasswordMsg").html(datares);
    }
  }

  okSuccCNOT(data,datares){
    //alert(data+" "+datares);
    if(data==="not_validated"){
      $("#getRegLogn").html(datares);
      return;
    }
    if(data!="0" && data!==""){
      clsObject.setClickFor("nav_otp");
      let useOTP=JSON.parse(data);
      $("#refotp_id").val(useOTP[0]["id"]);
      $("#refotp_contact_id").val(useOTP[0]["id"]);
      $("#refchangePassword_id").val(useOTP[0]["id"]);
      $(".opt_resp_num").html(datares);
    //  $("#getRegResp").html("Login Successfully..");
    //  localStorage.setItem('userDS',data);
    //  window.location.href="/home";
    }else{
      //clsObject.setClickFor("nav_otp");
      $("#getRegLognReg").html("Invalid UserName or Password !");
    }
}
okSuccOTP(data,datares){
  if(data==="not_validated"){
    $(".getRegLognOTP").html(datares);
    return;
  }
 
 
   if(data!=="0" && data!==""){
      $(".getRegLognOTP").html("Verify successfully thanks, please wait..");
     localStorage.setItem('userDS',data);
     //clsObject.setClickFor("nav_setpassword");
     setTimeout(function() {
      clsObject.setClickFor("nav_setpassword");
    }, 1000);
     
     //window.location.href="/home";
   }else{
    //alert("i m in red");
    $(".getRegLognOTP").html("Invalid OTP");
   }
}
  callBackpgData(data,msg){
    //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    //alert("successfully parse");
    //var mainHt=Cart_Canvas();
    var mainHt="";
    if(loc==="home"){
       // mainHt+=Video_Banner_Comp();
    }
    var injextI=0;
    var injCondition=false;
  
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                    mainHt+=products;
                }
           }
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    if(listObj.access.length==0){
        mainHt+=products;
    }
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();
    //$("#pgComp").html(mainHt);
    AOS.init();
    AOS.init({
      once: true
    });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
  
}
render(){
    return(<div>
        <Front_Header/>
        <div id="pgComp">
        <Comp_Narmadeayurvedam_Course/>
      </div>
      
      
<Footer/>
   </div>)
}
}
export default Tmp_Courses;
