import './comp_narmade_youtube.css';

const Comp_Narmade_Youtube=(props)=>{
    return(
        <section id="onfocus" class="onfocus section dark-background bg-area1 ">

        <div class="container p-0 " data-aos="fade-up">
  
          <div class="row g-0 pt-4">
            <div class="col-lg-6 video-play position-relative">
                <iframe class="framevideo img-thumbnail" width="100%" src="https://www.youtube.com/embed/ynfpu9yQsqw?si=CyrOqxtE69nlBkJp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  
            </div>
            <div class="col-lg-6">
              <div class="content d-flex flex-column justify-content-center h-100">
                <h3 class="center">Importance of Making the Right Choices</h3>
                <p class="fst-italic">
                    This video emphasizes the significance of making thoughtful decisions in life, showcasing how choices can impact both personal growth and overall well-being. It highlights the value of patience, persistence, and maintaining a positive outlook when facing challenges.
                </p>
                <ul>
                  <li>• Challenges and obstacles are inevitable, but our responses to them matter most.</li>
                  <li>• Patience and persistence are essential in navigating through difficult times.</li>
                  <li>• Positive thinking fosters resilience and drives success.</li>
                </ul>
                <a href="https://youtu.be/ynfpu9yQsqw?si=i3GjLoqNpwsqqYZR" class="read-more align-self-start"><span>Know More</span><i class="bi bi-arrow-right"></i></a>
              </div>
            </div>
          </div>
  
        </div>
  
      </section>
    );
}
export default Comp_Narmade_Youtube;