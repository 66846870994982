import './comp_narmadeayurvedam_course.css'

const Comp_Narmadeayurvedam_Course=(props)=>{
    return(
        <div class="container main-template-course-div mt-5 mb-5">
        <div class="starter-template">
          <h1>Course & Admission</h1>
          <p class="lead">Education and Lifestyle Courses
            Based on the ancient wisdom of Ayurveda.</p>
        </div>
        <div class="three-column-grid row">
          <div class="col-xs-12 col-md-4 col-lg-4">
           <h3> Ayurvedic Diet & Lifestyle</h3>
           <ul>
                <li>ayurvedic panchkarma</li>
                <li>ayurveda & detoxification</li>
                <li>ayurveda & yoga</li>
                <li>ayurveda & naturopathy</li>
                <li>ayurveda & physiotherapy</li>
                <li>Ayurveda & Health</li>
                <li>Ayurveda & Beauty</li>
                <li>Ayurveda & Meditation</li>
                <li> Ayurveda & Garbh Sanskar</li>
                <li> Ayurveda & Pregnancy Care</li>
                <li> Ayurveda & Swarnprashan</li>
                <li> Ayurveda & Nadi (Pulse Diagnosis)</li>
                <li> Ayurveda & Chronic Disease</li>
                <li>Ayurveda & Pain Management</li>
                <li> Ayurveda & Allergy</li>
                <li>Ayurveda & Hair Care</li>
                <li>Ayurveda & Skin Care</li>
                <li>Ayurveda & Metabolism</li>
                <li> Ayurveda & Vital Organs Care</li>
                <li>Ayurveda & Digestion</li>
                <li>Ayurveda & Hyper-Acidity</li>
                <li>Ayurveda & Gastritis</li>
                <li> Ayurveda & Constipation</li>
        </ul>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-4">
           <h3> Ayurveda & Pediatric Care (Child Care)</h3>
           <ul>
            
                <li>Ayurveda & Mental Health</li>
                <li>Ayurveda & Deaddiction</li>
                <li>Ayurveda & Carrier</li>
                <li>Ayurveda &Tridoshas</li>
                <li>Ayurveda & Body Types (Prakriti)</li>
                <li>Ayurveda & Panchmahabhutas</li>
                <li>Ayurveda & Sapta-Dhatus</li>
                <li>Ayurveda & Blood Diordres</li>
                <li>Ayurveda & Bone-Health</li>
                <li>Ayurveda & Body Strength</li>
                <li>Ayurveda & Cervical Care</li>
                <li>Ayurveda & Frozen Shoulder</li>
                <li>Ayurveda & Low Back Strength</li>
                <li>Ayurveda & Spine Care</li>
                <li>Ayurveda & Migraine</li>
                <li>Ayurveda & Cough-Cold-Fever-Body-Ache</li>
                <li>Ayuveda & Sinusitis</li>
                <li>Ayurveda & Eye Care</li>
                <li>Ayurveda & Oral Health</li>
                <li>Ayurveda & Calcullii</li>
                <li>Ayurveda & Aphrodisiac Effect</li>
                <li>Ayurveda & Nervine Strength</li>
                <li>Ayurveda & Rejuvenetaion ( Kaya-Kalp)</li>
           </ul>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-4">
           <h3> Ayurveda & Astrology</h3>
           <ul>
            <li>Ayurveda & Karm-Kand </li>
            <li>Ayurveda & Chanting</li>
            <li>Ayurveda & Collection Of Herbs</li>
            <li>Ayurveda & Important Medicinal Plants</li>
            <li>Ayurveda & Vedant</li>
            <li>Ayurveda & Pschycology</li>
            <li>Ayurveda & Mental Peace</li>
            <li>Ayurveda & Enthusiasm</li>
            <li>Ayurveda & Diabetes</li>
            <li>Ayurveda & Healthy Heart</li>
            <li>Ayurveda & Kitchen-Cooking</li>
            <li>Ayurveda & Panchgavya (Cow)</li>
            <li>Ayurveda & Me (You)</li>
           </ul>
          </div>
        </div>
  
      </div>
    );
}
export default Comp_Narmadeayurvedam_Course;