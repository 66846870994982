import './comp_scroll_message.css'
import marquee from "react-fast-marquee";
const Comp_Scroll_Message=(props)=>{
    return(
        <div class="bhandarkar-notice">

            
{/* 
<div class="marquee">
  <span>Hover over this text to pause the scrolling marquee.</span>
</div> */}
<marquee behavior="scroll" direction="left"
           onmouseover="this.stop();"
           onmouseout="this.start();">
<a class="white" href={props.lsData[0]["col7"]}>{props.lsData[0]["col8"]}  </a>
</marquee>

    {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shopNowModal">
    Notice
    </button> */}


<div class="modal fade" id="shopNowModal" tabindex="-1" aria-labelledby="shopNowModalLabel" aria-hidden="true">
  <div class="modal-dialog">
 
    <div class="modal-content">
      <div class="modal-body">
        
        <img src="Bhandarkarr.png" alt="Shop Image" class="modal-image"/>

    
        <button type="button" class="btn btn-success shop-now-btn">Shop Now</button>
      </div>
    </div>
  </div>
</div>
</div>
    );
}
export default Comp_Scroll_Message;