import $ from 'jquery';
import React from 'react';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack
    ,ajaxindicatorstart,ajaxindicatorstop,RespBkMthdMapp,btnSetColor_CBack} from '../function_lib/common_lib';
import {getUserObjData} from '../function_lib/userToken';
// import {Stripe} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { CheckoutForm } from './pay_now';
import Front_Header from './front_header';
import Footer from './footer';
import AOS from 'aos';
import {Comp_Shipping_Selection} from '../component_version2/comp_shipping_selection';
import Comp_Gift_OTP from '../component_version2/comp_gift_otp';
//import Test from './test.js';
import Comp_Address_Selection from '../component_version2/comp_address_selection';
import './step_two.css';

let clsObject;
let cartItems;
let finalAmt=0;
let userObject;

let couponDiscount=0;
let _taxAmount=0;
let _shipingCost=0;

let giftCodeDiscount=0;

/* shipping state and zipcode cross amend */
let shipState=0;
let shipZipCode="";

class Step_Two_CC extends React.Component{
    
    handleBackButton = (event) => {
        event.preventDefault();
        alert('Back button clicked!');
        console.log('User clicked back button');
        // Additional logic can be added here
      };
      

    constructor(props){
        super(props);
        cartItems=localStorage.getItem("_cart");
  
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
            cartItems=JSON.parse(cartItems);
        }
        this.state={stCoupon_data:{},stShipping_data:[],stTax_data:[]
        ,stNTrigger:"",stShipingCost:"0",stGiftCode_otp:[],stGiftCode_data:[]}
        userObject= getUserObjData();

        
        //alert(userObject[0].id);
        //alert("i m in step two constru");
    }

    checkShipping(){
        let chkShip=document.getElementById("diff_shipping").checked;
        //alert(chkShip);
        $("#b_name").val("");
        $("#b_country").val("");
        $("#b_state").val("");
        $("#b_city").val("");
        $("#b_pincode").val("");
        $("#b_address1").val("");
        $("#b_address2").val("");
        if(chkShip){
            $("#b_name").val($("#s_name").val());
            $("#b_contact_no").val($("#s_contact_no").val());
            $("#b_country").val($("#s_country").val());
            $("#b_state").val($("#s_state").val());
            $("#b_city").val($("#s_city").val());
            $("#b_pincode").val($("#s_pincode").val());
            $("#b_address1").val($("#s_address1").val());
            $("#b_address2").val($("#s_address2").val());

            if($("#s_state").val()==null){
                this.getStateShipping();
            }
        }
    }
    detectPaymentElement(invoiceId=0){
        //alert(localStorage.getItem("purchase_token"));
        //alert("i m in detect pf backspace");
        if(invoiceId===0){
            //if invoice id is 0 , it means we need to check if purchase_token generated means user came to this page through back button

            if(localStorage.getItem("purchase_token")!==null){
              //alert("we have found recently payment process pending for invoice ..");
              let pToken=localStorage.getItem("purchase_token");
              localStorage.removeItem("purchase_token");
              window.location.href="../invoice_receipt?rf="+pToken;
              return false;
            }
        }else{
            localStorage.setItem('purchase_token',invoiceId);
            return true;
        }
        
        
    }
    updatePaymentStatus(params,vref){
        //let tokenId=locUrl.pathname.split('/')[2];
        //let params= QueryString.parse(locUrl.search);
        //alert(params)
        // alert(JSON.stringify(vref));
        // return;
        if(params.redirect_status==="succeeded"){
            alert("Order placed successfully");
        }
    
        let mapStrip=[];
        mapStrip.push({name:"curl_type",value:"updateRefSecToken"});
        mapStrip.push({name:"res_func",value:"callBackSt"});
        mapStrip.push({name:"token",value:vref});
        mapStrip.push({name:"payment_intent",value:vref});
        mapStrip.push({name:"redirect_status",value:btoa(params.redirect_status)});
        mapStrip.push({name:"payment_intent_resp_secret",value:params.razorpay_payment_id});
        mapStrip.push({name:"payment_intent_client_secret",value:vref})
    
            mapStrip["res_func"]="callBackSt";
            mapStrip["curl_type"]="updateRefSecToken";
            ajaxindicatorstart("Wait for order processing..");
            CallMapMethod_CBack(this,mapStrip,function(data,msg){
                //alert(data);
                ajaxindicatorstop();
                if(data!=="0" && data!==""){
                    //let jsData=JSON.parse(data);
                    //const clientSecret = jsData.client_secret;
                    //window.location.href="../invoice_receipt?rf="+params.razorpay_payment_id+"&rfkey="+vref;   
                    window.location.href="../invoice_receipt?rf="+vref;
          
                }
            })
    }

    submitInvoiceToPay(){
       
       // window.history.pushState({}, null, null);
       // return;
         /* check other shipping validation */
        //alert("["+this.state.stShipingCost +"],["+this.state.stNTrigger+"]");
        if(this.state.stShipingCost==="0" || this.state.stNTrigger===""){
            alert("Kinldy check State Name and Zipcode to Process");
            $("#s_pincode").val("");
            $("#b_pincode").val("");
            return;
        }
        if(shipState!==$("#s_state").val()){
            alert("Shipping [State Name] Mismatched, kindly check again or fill all information again");
            return;
        }
        if(shipZipCode!==$("#s_pincode").val()){
            alert("Shipping Zip Code Mismatched or Changed, kindly check again or fill all information again");
            return;
        }
        /*
        if(clsObject.detectPaymentElement()===false){
            return;
        }
        */

        // return;
       
        btnSetColor_CBack(clsObject,"frmShippingBilling","addToInvoiceUS",function(data,msg){
            if(data==="not_validated"){
                $("#getRegLogn").html(msg);
                return;
              }
              if(data!=="" && data!=="0"){
                let jsInvoice=JSON.parse(data);
                // window.location.href="https://adlog.fastrcdirect.com/securePayNow?token="+data;
                window.location.href="https://www.narmadeayurvedam.com/adlog/extPgControl.php?pm=securePaymentRequest&token="+jsInvoice.invoice_id;
                 //clsObject.detectPaymentElement(data);
                 //clsObject.StripPyament(data);
                //  razor
                //alert(data);
                
                       
               }
        });
       
       
    }

    StripPyament(tokenId){
        let mapStrip=[];
        mapStrip.push({name:"curl_type",value:"securePayNowStripe"});
        mapStrip.push({name:"res_func",value:"callBackSt"});
        mapStrip.push({name:"token",value:tokenId});
        mapStrip["res_func"]="callBackSt";
        mapStrip["curl_type"]="securePayNowStripe";
        CallMapMethod_CBack(clsObject,mapStrip,function(data,msg){
            //alert(data);
            if(data!=="0" && data!==""){
                $("#payment_token").val(data);
                let jsData=JSON.parse(data);
                jsData.tokenId=tokenId;
                const clientSecret = jsData.client_secret;
                window.location.href="../initpayment?secret="+clientSecret+"&token="+tokenId+"&tm="+btoa(jsData.amount);   
      
            }
        })
    }

    getState(){
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackSt"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#b_country").val()});
        mapCat.push({name:"p2",value:"1"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackSt";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackSt(data,msg){
        if(data!=="" && data!=="0"){
            var listState=JSON.parse(data);
            var strState="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listState.length;i++){
                strState+="<option value=\""+listState[i]["location_id"]+"\">"+listState[i]["name"]+"</option>"
            }
            $("#b_state").html(strState);
        }
    }

    getStateShipping(){
        
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackStShip"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#s_country").val()});
        mapCat.push({name:"p2",value:"1"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackStShip";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackStShip(data,msg){
        //alert(data);
        if(data!=="" && data!=="0"){
            var listState=JSON.parse(data);
            var strState="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listState.length;i++){
                strState+="<option value=\""+listState[i]["location_id"]+"\">"+listState[i]["name"]+"</option>"
            }
            $("#s_state").html(strState);
            if(document.getElementById("diff_shipping").checked){
                this.checkShipping();
            }
            
        }
    }


    getCountry(){
       
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getCountry"});
        mapCat.push({name:"res_func",value:"callBackCntry"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:"0"});
        mapCat.push({name:"p2",value:"0"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackCntry";
        mapCat["curl_type"]="getCountry";
        CallMapMethod(clsObject,mapCat);
    }
    callBackCntry(data,msg){
        if(data!=="" && data!=="0"){
            var listCountry=JSON.parse(data);
            //var strCountry="<option value=\"0\">-Select-</option>";
            var strCountry="";
            for(var i=0;i<listCountry.length;i++){
                if(listCountry[i]["location_id"]==="100"){
                    strCountry+="<option selected value=\""+listCountry[i]["location_id"]+"\">"+listCountry[i]["name"]+"</option>"
                }
                
            }
            $("#b_country").html(strCountry);
            $("#s_country").html(strCountry);

           clsObject.getState();
           clsObject.getStateShipping();
        }
       // alert(data);
    }
    checkShippingCost(){
         /* Set Cart Item Cost */
       let totalCost=0;
       for(var ci=0;ci<cartItems.length;ci++){
           totalCost+= parseFloat(cartItems[ci]["item_rate"]);
       }
       //alert(totalCost);
       if(parseFloat(totalCost)<500){
        $(".shipping_cost").html("₹"+"43.00");
        //alert(totalCost);
        $(".tot_withship").html("₹"+(parseFloat(totalCost)+43).toFixed(2));
        if($("#b_state").val()!=="744"){
            $(".shipping_cost").html("₹"+"70.00");
            $(".tot_withship").html("₹"+(parseFloat(totalCost)+70).toFixed(2));
        }
       }else{
        $(".shipping_cost").html("Free Shipping");
       }
       
    }
    calculateTax(lsTaxRate){
        if(typeof lsTaxRate!=="undefined" && lsTaxRate.length>0){
            if(typeof lsTaxRate[0].total_rate!=="undefined"){
                let tmpRate=parseFloat(lsTaxRate[0]["total_rate"])*100;
                _taxAmount=parseFloat((finalAmt*tmpRate)/100).toFixed(2);
                //alert(finalAmt);
                lsTaxRate[0]["net_rate"]=(parseFloat(lsTaxRate[0]["total_rate"])*100).toFixed(2);
                lsTaxRate[0]["_tax_amount"]=_taxAmount;
            }
            //alert(JSON.stringify(clsObject.state.stTax_data));
        }

    }
    checkTaxData(pincode){

    //     let frmName="frmShippingBilling";
    //     let objElems=document.getElementById(frmName).getElementsByClassName("required");
    //     let isValidated=true;
    //    if($("#s_name").val().trim()==="" || $("#s_address1").val().trim()===""
    //    || $("#s_country").val().trim()==="0" || $("#s_state").val().trim()===""
    //    || $("#s_city").val().trim()==="" || $("#s_pincode").val().trim()===""){
    //     isValidated=false;
    //    }
        /* collect cart items and check LBH */
         /* cart element listing */
         let cartItems= localStorage.getItem("_cart");
        //let pItemList=JSON.stringify(cartItems);
         let pItemList=cartItems;
        //alert(cartItems);
         let getItem= localStorage.getItem("_cart");
         var items=[];
         if(getItem!=null && getItem!==""){
            getItem=JSON.parse(localStorage.getItem("_cart"));
         }else{
            getItem=[];
         }
         let itemIds="";
        
         for(let i=0;i<getItem.length;i++)
         {
            if(itemIds!==""){
                itemIds+=",";
            }
            itemIds+=getItem[i]["item_rf_id"];
         }
        //alert(itemIds);
      

        let mapCat=[];
        mapCat.push({name:"curl_type",value:"getShippingInfoIN"});
        mapCat.push({name:"res_func",value:"callBackProCoupon"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"pincode",value:$("#s_pincode").val()});

        /* call data for shipping api merge */
        mapCat.push({name:"s_name",value:$("#s_name").val()});
        mapCat.push({name:"s_address1",value:$("#s_address1").val()});
        mapCat.push({name:"s_address2",value:$("#s_address2").val()});
        mapCat.push({name:"s_country",value:$("#s_country").val()});
        mapCat.push({name:"s_state",value:$("#s_state").val()});
        mapCat.push({name:"s_city",value:$("#s_city").val()});
        mapCat.push({name:"s_pincode",value:$("#s_pincode").val()});
        mapCat.push({name:"item_ids",value:itemIds});
        mapCat.push({name:"item_list_token",value:cartItems});
        
        mapCat["res_func"]="callBackProCoupon";
        mapCat["curl_type"]="getShippingInfoIN";
        

        let isValidated=true;
        for(let i=0;i<mapCat.length;i++){
            //alert(mapCat[i].name+" = "+mapCat[i].value);
            if(typeof mapCat[i].value!=="undefined")
            {
                //alert(mapCat[i].name);
                if(document.getElementById(mapCat[i].name)!==null){
                    if(document.getElementById(mapCat[i].name).hasAttribute("required")){
                        if(mapCat[i].value.trim()==="" || mapCat[i].value.trim()==="0"){
                            isValidated=false;
                            document.getElementById(mapCat[i].name).style.border="1px solid #e87d1e";
                        }
                    }
                }
            }
          
        }
        if(!isValidated){
            $("#s_pincode").val("");
             return ; 
           }
           /* first reinit componetn */ 
           _shipingCost=0;
           $("#shipping_cost").text("TBD");
           /* end of reinit component */
        
           ajaxindicatorstart("please wait");
        CallMapMethod_CBack(clsObject,mapCat,function(data,msg){
            ajaxindicatorstop();
            //alert(msg);
            if(data!=="0" && data.trim()!==""){

                 let lsTaxNshipping=JSON.parse(data);
            //     let lsTaxRate=lsTaxNshipping.pincode_tax;
            // clsObject.calculateTax(lsTaxRate);
            let lsShippingRates=[];
            if(typeof lsTaxNshipping.shipping_data!=="undefined"){
                //alert("i m in shipping data");
                lsShippingRates=lsTaxNshipping.shipping_data;
            }
            //set ShippingData from Here for Default Selection
            if(lsShippingRates.length>0){
                let data1=lsShippingRates[0]["retail_rate"];
                let data2=lsShippingRates[0]["carrier_account_id"]+","+lsShippingRates[0]["carrier"]+"-"+lsShippingRates[0]["service"]+","+lsShippingRates[0]["retail_rate"];

                clsObject.callBackOnShipService(data1,data2);

            }
            
            //End of Shipping Default Selection


            
          //below code not required when shipping is in default selection 
            //    clsObject.setState({stTax_data:[],stShipping_data:lsShippingRates},()=>{
            //     RespBkMthdMapp(clsObject,"_setbackparam","callBackOnShipService")
            //    });
                      
                }
        });
    }
    callBackOnShipService(data1,data2){
        //alert(data1);
        _shipingCost=parseFloat(data1);
        $("#shipping_cost").text(data1);
        finalAmt=finalAmt+parseFloat(data1);
        
        //clsObject.calculateTax(clsObject.state.stTax_data);
        shipState=$("#s_state").val();
        shipZipCode=$("#s_pincode").val();
        this.setState({stNTrigger:data1,stShipingCost:data2});
    }

    checkGiftCode(){
        //alert("check calls");
        let mapCat=[];
        mapCat.push({name:"curl_type",value:"checkGiftCode"});
        mapCat.push({name:"res_func",value:"callBackGiftCo"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"gift_code",value:$("#gift_card_id").val()});

        mapCat["res_func"]="callBackGiftCo";
        mapCat["curl_type"]="checkGiftCode";

        CallMapMethod_CBack(clsObject,mapCat,function(data,msg){
            //alert(data+msg);
            if(data!=="0"){
                let lsGiftOtp=JSON.parse(data);
                clsObject.setState({stGiftCode_otp:lsGiftOtp})
            }
        })
    }
    applyGiftCodeOTP()
    {
        let mapCat=[];
        mapCat.push({name:"curl_type",value:"checkOTPGiftCode"});
        mapCat.push({name:"res_func",value:"callBackGiftCo"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"gift_code",value:$("#gift_card_id").val()});
        mapCat.push({name:"gift_otp",value:$("#gift_card_otp").val()});

        mapCat["res_func"]="callBackGiftCo";
        mapCat["curl_type"]="checkOTPGiftCode";

        CallMapMethod_CBack(clsObject,mapCat,function(data,msg){
            //alert(data+msg);
            if(data!=="0"){
                let lsGiftOtp=JSON.parse(data);

                giftCodeDiscount=parseFloat(lsGiftOtp[0]["amount"]);
                //alert(giftCodeDiscount+"==="+finalAmt);
                if(giftCodeDiscount>finalAmt){
                   // alert("i m in setting giftcode");
                    giftCodeDiscount=finalAmt;
                    lsGiftOtp[0]["net_amount"]=lsGiftOtp[0]["amount"];
                    lsGiftOtp[0]["amount"]=finalAmt;
                }else{
                    lsGiftOtp[0]["net_amount"]=lsGiftOtp[0]["amount"];
                }
                clsObject.setState({stGiftCode_data:lsGiftOtp});
            }
            $("#giftOtpResponse").html(msg);
        })
    }

    checkCouponCode(){
        //alert(finalAmt);
        let mapCat=[];
        mapCat.push({name:"curl_type",value:"checkCouponCode"});
        mapCat.push({name:"res_func",value:"callBackProCoupon"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#coupon_disc").val()});
        mapCat.push({name:"loc_token",value:userObject[0].id});
        mapCat.push({name:"p2",value:finalAmt});
        mapCat["res_func"]="callBackProCoupon";
        mapCat["curl_type"]="checkCouponCode";
        CallMapMethod_CBack(clsObject,mapCat,function(data,msg){
            //alert(data);
            if(data==="0" || data===0){
                alert(msg);
            }
            if(data!=="0"){
                finalAmt=0;
                let couponData=JSON.parse(data);
                //alert(data);
                couponDiscount=parseFloat(couponData["amount"]).toFixed(2);
                couponData.amount=parseFloat(couponData["amount"]).toFixed(2);
                //$("#coupon_break").val(couponDiscount);
                //alert(couponDiscount);
                //alert(JSON.parse(couponData));
                clsObject.setState({stCoupon_data:couponData})
                }
        });
    }
    componentWillUnmount() {
        window.removeEventListener('popstate', this.handleBackButton);
      }
    componentDidMount(){
        //alert(window.document.referrer);
        //window.addEventListener('popstate', this.handleBackButton);
        //window.history.pushState({page:1},"testin1", window.location.href);
       // window.history.pushState({page:1}, "testin2",window.location.href);
        //window.history.pushState({"ttt":Math.floor((Math.random() * 100000) + 1)}, null, null);
        //$("#random_bkg").click();
        //$("#random_bkg").val(Math.floor((Math.random() * 100000) + 1));
        //console.log("check event1451"+Math.floor((Math.random() * 100000) + 1));
        //window.history.pushState({"ttt":Math.floor((Math.random() * 100000) + 1)}, null, null);
        
        clsObject=this;
        this.getCountry();
        //clsObject.detectPaymentElement();//to check payment token
       // alert("check data");
       AOS.init();
       AOS.init({
         once: true
       });
       const script = document.createElement("script");
       script.src = "/main.js";
       script.async = true;
       //script.onload = () => clsObject.scriptLoaded();
     
       document.body.appendChild(script);

       AjaxCallMethod(clsObject,"okSuccCNOT");
       //Init 
       $("#loc_token").val(userObject[0].id);
       var cartItems= localStorage.getItem("_cart");
       let pItemList=JSON.stringify(cartItems);
       //let resPList ="{\"tt\":["+pItemList+"]}";
       //alert(JSON.parse(cartItems).length);
       $("#item_list_token").val(cartItems);

    //    $(".cd_input_change").change(function () {
    //     alert($(this).val().length);
    //    })
    $('.cd_input_change').keyup(function (e) {
        //alert($(this).val().length);
        if($(this).val().length>=6){
            clsObject.checkTaxData($(this).val());
        }
      });

    }
    render(){
        return(<div>
             <Front_Header/>
             
             {/* <!-- checkout area start --> */}
             {/* <input type="text" readonly={"true"} name="random_bkg" id="random_bkg"/> */}
            <div className="checkout-area pt-100px pb-100px">
            <div className="container">
                <div className="row">
                <div className="col-lg-5 mt-md-30px mt-lm-30px ">
                        <div className="bill-pbox your-order-area">
                            <h3>Order Details</h3>
                            {/* billing and payment summary */}
                            <div className="your-order-wrap gray-bg-4 mb-4 box">
                            <div className="your-order-product-info">
                            {(cartItems) ? cartItems.map((item,index)=>{
            if(index===0){finalAmt=0;window.cAmount=0}
            finalAmt+=parseFloat(item.amount);
            window.cAmount=finalAmt;}
            ):''}
                                  <div className="your-order-bottom">
                                        <ul>
                                            <li className="your-order-shipping">Sub Total</li>
                                            <li className='shipping_cost'>₹{finalAmt}</li>
                                        </ul>
                                        {/* {this.state.stTax_data.length>0?<ul>
                                            <li className="your-order-shipping">Tax ({parseFloat(this.state.stTax_data[0]["net_rate"]).toFixed(2)}%)</li>
                                            <li className='shipping_cost'>{_taxAmount}</li>
                                        </ul>:
                                        <ul>
                                            <li className="your-order-shipping">Estimated Tax</li>
                                            <li className='shipping_cost'>TBD</li>
                                        </ul>
                                        } */}
                                        
                                        <ul>
                                            <li className="your-order-shipping">Estimated Shipping</li>
                                            <li id="shipping_cost">TBD</li>
                                        </ul>
    <Comp_Shipping_Selection shipping_data={this.state.stShipping_data}/>
                                       {typeof this.state.stCoupon_data.item_name!=="undefined"?
                                             <ul className="mt-2">
                                                 <li className="your-order-shipping">{this.state.stCoupon_data.item_name}</li>
                                            <li className='shipping_cost'>{-this.state.stCoupon_data.amount}</li>
                                            </ul>:
                                            <ul className="mt-2">
                                            <li className="your-order-shipping">
                                            <input className="form-control" type='text' id="coupon_disc" name="coupon_disc" placeholder='coupon code' />
                                            </li>
                                            <li className="shipping_cost">
                                            <a href="javascript:void(0)"><span onClick={()=>this.checkCouponCode()} className='float-right badge bg-primary'>Apply</span></a>

                                            </li>
                                        </ul>}
                                        
                                         
                                       {this.state.stGiftCode_data.length>0?
                                    <ul className="mt-2">
                                         <li className="your-order-shipping">{"Gift Code Discount"}</li>
                                         <li className='shipping_cost'>{-this.state.stGiftCode_data[0].amount}</li>
                                    </ul>:  
                                    <ul className="mt-2">
                                    <li className="your-order-shipping">
                                    <input className="form-control" type='text' id="gift_card_id" name="gift_card_id" placeholder='gift card code' />
                                    </li>
                                    <li className="shipping_cost">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#divGiftOTP"><span onClick={()=>this.checkGiftCode()} className='float-right badge bg-primary'>Apply</span></a>
                                </li>
                                </ul> 
                                    }
                                       
                                    </div>
                                   
                                    <div className="your-order-total">
                                        <ul>
                                            <li className="order-total">Total</li>
                                            <li className='tot_withship'>₹ {(parseFloat(finalAmt-(couponDiscount+giftCodeDiscount))+parseFloat(_taxAmount)+parseFloat(_shipingCost)).toFixed(2)}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="your-order-wrap gray-bg-4 box">
                                <div className="your-order-product-info">
                                    <div className="your-order-top">
                                        <ul>
                                            <li>Product</li>
                                            <li>Qty</li>
                                            <li>Total</li>
                                        </ul>
                                    </div>
                                    <div className="your-order-middle">
                                        <ul>
                                        {(cartItems) ? cartItems.map((item,index)=>{
            if(index===0){finalAmt=0;window.cAmount=0}
            finalAmt+=parseFloat(item.amount);
            window.cAmount=finalAmt;
           return <li><span className="order-middle-left">{item.item_name} X {item.item_quantity}</span> <span className="order-price">₹{item.amount}</span></li>
                                           }):''
                                    }
                                        </ul>
                                    </div>
                                   
                                </div>
                                <div className="payment-method">
                                    <div className="payment-accordion element-mrg">
                                        <div id="faq" className="panel-group">
                                            <div className="panel panel-default single-my-account m-0">
                                                {/* <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-1" className="collapsed" aria-expanded="true">Shipping Details</a>
                                                    </h4>
                                                </div> */}
                                                {/* <div id="my-account-1" className="panel-collapse collapse show" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Free Shipping on All Orders Over $ 500!</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-2" aria-expanded="false" className="collapsed">Check payments</a></h4>
                                                </div>
                                                <div id="my-account-2" className="panel-collapse collapse" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="panel panel-default single-my-account m-0">
                                                <div className="panel-heading my-account-title">
                                                    <h4 className="panel-title"><a data-bs-toggle="collapse" href="#my-account-3">Cash on delivery</a></h4>
                                                </div>
                                                <div id="my-account-3" className="panel-collapse collapse" data-bs-parent="#faq">
            
                                                    <div className="panel-body">
                                                        <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-box col-lg-7">
                        <div className="billing-info-wrap">
                        <form id="frmShippingBilling" name="frmShippingBilling">
                        <input type="hidden" name="curl_type" id="curl_type" value="addToInvoiceUS" />  
                <input type="hidden" name="m_type" id="m_type" value="addToInvoiceUS" />
                <input type="hidden" name="loc_token" id="loc_token" />
                <input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />
                <input type="hidden" name="item_list_token" id="item_list_token" />
                <input type="hidden" name="tax_token" id="tax_token" value={btoa(JSON.stringify(this.state.stTax_data))}/>
                <input type="hidden" name="final_amount" id="final_amount" value={finalAmt} />
                <input type="hidden" name="coupon_break" id="coupon_break"/>
                <input type="hidden" name="coupon_apply" id="coupon_apply"  value={btoa(JSON.stringify(this.state.stCoupon_data))}/>
                <input type="hidden" name="rd_shipping_value_fc" id="rd_shipping_value_fc" value={btoa(this.state.stShipingCost)} />
                
                {this.state.stGiftCode_data.length>0?<div>
                    <input type="hidden" name="giftcode_apply" id="giftcode_apply" value={btoa(JSON.stringify(this.state.stGiftCode_data))} />
                </div>:''}
                <input type="hidden" name="res_func" id="res_func" value="okSuccInv" />
                <div id="shiiping_add">
                               
                               <h3>Shipping Address</h3>
                               <div className="row">
                                   <div className="col-lg-12 col-md-12">
                                       <div className="billing-info mb-4">
                                           <label>Full Name</label>
                                           <input className="form-control required" id="s_name" name="s_name" type="text" />
                                       </div>
                                   </div>
                                   <div className="col-lg-12 col-md-12">
                                       <div className="billing-info mb-4">
                                           <label>Contact No.</label>
                                           <input className="form-control required" id="s_contact_no" name="s_contact_no" type="number" />
                                       </div>
                                   </div>
                                   <div className="col-lg-12">
                                       <div className="billing-info mb-4">
                                           <label>Address</label>
                                           <input className="billing-address form-control required11" id="s_address1" name="s_address1" placeholder="House number and street name" type="text" />
                                           <input className="form-control" placeholder="Apartment, suite, unit etc." id="s_address2" name="s_address2" type="text" />
                                       </div>
                                   </div>
                                   <div className="col-lg-12">
                                       <div className="billing-select mb-4">
                                           <label>Country</label>
                                           <select className="form-control select_target required" resp_dest="getStateShipping" id="s_country" name="s_country">
                                               <option>Select a country</option>
                                             
                                           </select>
                                       </div>
                                   </div>
                                   <div className="col-lg-12">
                                       <div className="billing-select mb-4">
                                           <label>State</label>
                                           <select className="form-control required" id="s_state" name="s_state">
                                              
                                           </select>
                                       </div>
                                   </div>
                                 
                                   <div className="col-lg-g col-md-6">
                                       <div className="billing-info mb-4">
                                           <label>Town / City</label>
                                           <input className="form-control required" id="s_city" name="s_city" type="text" />
                                       </div>
                                   </div>
                                  
                                   <div className="col-lg-6 col-md-6">
                                       <div className="billing-info mb-4">
                                           <label>ZIP Code</label>
                                           <input className="form-control required cd_input_change" id="s_pincode" name="s_pincode" type="text" />
                                       </div>
                                   </div>
                                   
                                   
                               </div>
                              
                               </div>
                               <br></br>
                            <hr></hr>
                            <p className="bottomtxt"> <input type="checkbox" onClick={()=>this.checkShipping()} id="diff_shipping" name="diff_shipping" value="Different Shipping" />
<label for="diff_shipping">Same As Shipping Address</label></p>
                           <div id="billing_add">
                            <h3>Billing Address</h3>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="billing-info mb-4">
                                        <label>Full Name</label>
                                        <input className="form-control" id="b_name" name="b_name" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="billing-info mb-4">
                                        <label>Contact No</label>
                                        <input className="form-control" id="b_contact_no" name="b_contact_no" type="number" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-4">
                                        <label>Address</label>
                                        <input className="billing-address required11 form-control" id="b_address1" name="b_address1" placeholder="House number and street name" type="text" />
                                        <input className="form-control" placeholder="Apartment, suite, unit etc." id="b_address2" name="b_address2" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>Country</label>
                                        <select className="form-control required select_target" resp_dest="getState" id="b_country" name="b_country">
                                            <option>Select a country</option>
                                          
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>State</label>
                                        <select className="form-control required"  id="b_state" name="b_state">
                                           
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-g col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Town / City</label>
                                        <input className="form-control required" id="b_city" name="b_city" type="text" />
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>ZIP Code</label>
                                        <input className="form-control required cd_input_change11" id="b_pincode" name="b_pincode" type="text" />
                                    </div>
                                </div>
                               
                               
                               
                            </div>
                          
                            </div>
                          
                          {/* shipping sift */}
                         
                            {/* <div className="additional-info-wrap">
                                <h4>Additional information</h4>
                                <div className="additional-info">
                                    <label>Order notes</label>
                                    <textarea placeholder="Notes about your order, e.g. special notes for delivery. " name="message"></textarea>
                                </div>
                            </div> */}
                            <div id="getRegLogn" style={{color:"red"}}></div>
                            <div className="Place-order mt-25">
                                        <button className="btn btn-primary btn-outline-dark current-btn _btnSetColor111" onClick={()=>this.submitInvoiceToPay()} type="button"><span>Place Order</span></button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <initPayment />
             {/* <!-- checkout area end --> */}
           <Footer/>
           <Comp_Gift_OTP giftOTP={this.state.stGiftCode_otp} fnOTPConfirmation={this.applyGiftCodeOTP}/>
           </div>
            )
    }
}
export default Step_Two_CC;